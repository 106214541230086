#recap {
  padding: 10px;
  background-color: #3f3f3f;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: fixed;
  width: 100%;
  z-index: 2;
  color: #EEEEEE;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#maj {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
}

#button_deleted_all {
  width: 70%;
  font-size: 70%;
  margin-top: 5px;
  font-weight: bold;
  color: #161616;
}

h4,
h3,
h6 {
  margin: 0;
}

#recap h4{
  font-size: 15px;
}

#recap_total_stable {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #3f3f3f;
  padding: 1%;
}

#recap_total_crypto,
#recap_total_stable {
  background-color: #EEEEEE;
  color: black;
  padding: 1%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

#recap_left {
  width: 30%;
}

#recap_total_investi,
#recap_portefeuille_actuel,
#recap_gains_pertes,
#recap_roi {
  background-color: #EEEEEE;
  color: black;
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align-last: center;
  justify-content: space-around;
  text-align: center;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
}

#alert{
  width: 100%;
    height: 100%;
    position: absolute;
    text-align-last: center;
}

#grey_text {
  font-family: 'Spartan';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EEEEEE;
}

@media (max-width: 800px) {

  #recap_total_investi h4,
  #recap_portefeuille_actuel h4,
  #recap_gains_pertes h4,
  #recap_roi h4,
  #recap_total_crypto h4,
  #recap_total_stable h4 {
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 10px;
  }
}

@media (max-width: 600px) {
  #recap_smartphone {
    padding: 1%;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    position: fixed;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: stretch;
    z-index: 2;
    background-color: #3f3f3f;
    color: #EEEEEE;
    border-bottom: solid 2px black;
    height: 22%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  #button_deleted_all {
    width: 18%;
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 700;
    font-size: 7px;
    line-height: 7px;
    display: flex;
    align-items: center;
    text-align: center;
    align-self: center;
    color: #161616;
    justify-content: center;
    font-weight: bold;  
  }

  #recap_total_investi,
  #recap_portefeuille_actuel,
  #recap_gains_pertes,
  #recap_roi {
    background-color: #EEEEEE;
    color: black;
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    text-align-last: center;
    justify-content: space-between;
    text-align: center;
    padding: 1%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  #maj {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
  }

  #recap_smartphone h3 {
    color: #d1ceff;
  }

  #recap_right {
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
    height: 30%;
  }

  #recap_left {
    display: flex;
    width: 95% !important;
    flex-direction: column;
    align-items: stretch;
  }

  #grey_text {
    font-family: 'Spartan';
    font-style: normal;
    font-weight: 400;
    font-size: 7px;
    line-height: 8px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #EEEEEE;
  }

  #alert{
    position: absolute;
    left: 18%;
  }
}