/* load : the load div*/
#load {
  position: absolute;
  width: 100%;
  display: flex;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 15000;
  background-color: #c4c4c4;
}
