/* div_tarif_type_1 : the freemium tarif division */
/* div_tarif_type_2 : the novice tarif division */
/* div_best_tarif_type : the pro tarif division */
/* div_tarif_type_4 : the expert tarif division */
#div_tarif_type_1,
#div_tarif_type_2,
#div_best_tarif_type,
#div_tarif_type_4 {
  background-color: #3f3f3f;
  margin-bottom: 4%;
  padding: 3%;
  border-radius: 10px;
  text-align: -webkit-center;
  box-shadow: 3px 5px 14px black;
  width: 45%;
  color: white;
  max-width: 550px;
}

#tarif {
  width: 100%;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Spartan';
}

#div_tarif_type_1:hover,
#div_tarif_type_2:hover,
#div_best_tarif_type:hover,
#div_tarif_type_4:hover {
  box-shadow: 5px 15px 14px black;
  cursor: pointer;
}

/* div_best_tarif_type : the pro tarif division */
#div_best_tarif_type {
  border: solid #83cb2b;
  padding: 3%;
}

/* prix : the price in each div*/
#prix {
  text-align-last: left;
  margin: 0px;
}

/* text_best_price : the price in div_best_tarif_type*/
#text_best_price {
  margin: 0;
}

/* title_tarif : all the title in div*/
#title_tarif {
  margin-top: 0px;
}

/* title_tarif : header best price div*/
#best_prix {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  color: #83cb2b;
  height: 10%;
}

/*for the responsive design*/
@media (max-width: 600px) {
  #div_tarif_type_1,
  #div_tarif_type_2,
  #div_best_tarif_type,
  #div_tarif_type_4 {
    background-color: #2f2f2f;
    margin-bottom: 10%;
    padding: 3%;
    border-radius: 10px;
    text-align: -webkit-center;
    box-shadow: 3px 5px 14px black;
    width: 90%;
  }
}
