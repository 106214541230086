/*home : the home div*/
#home {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

#button_redirect_youtube {
  background-color: red;
  color: white;
  z-index: -1;
}

/*youtube_player : the youtube player*/
#youtube_player,
#youtube_player1 {
  width: 100%;
  height: 60vw;
  max-width: 1000px;
  max-height: 500px;
}
/*youtube_container : the container which contains the youtube player*/
.youtube_container {
  width: 100%;
  background-color: #353535;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0px 0px 48px 20px black;
}

/*for the responsive design*/
@media (max-width: 600px) {
  #home {
    padding: 2%;
  }
}
