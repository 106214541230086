/* settings : the settings div*/
#settings,
#settings_smartphone {
  background-color: #343434;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 10px;
}

/* settings : the settings div for smartphone*/

/* hrrr : the hr from the settings div*/
#hrrr {
  width: 98%;
  margin-top: 20px;
  border-top: 4px;
}

/* message_double_auth : the 2fa message*/
#message_double_auth {
  margin-top: 0;
}

/* settings_button : all the settings button*/
#settings_button {
  background-color: black;
  color: white;
  text-decoration: none;
}

/* titre_section_settings : all the section title*/
#titre_section_settings {
  color: white;
}

/* settings_link : all the settings links*/
#settings_link {
  /*color: white;*/
  text-align: center;
}

/* deconnexion_button : the sign out button*/
#deconnexion_button {
  color: red;
}

/* link_section : the settings section */
#link_section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#profile_photo {
  border-radius: 50px;
}

@media (max-width: 600px) {
  #settings,
  #settings_smartphone {
    background-color: #c4c4c4;
    margin-bottom: 100px;
  }
}
