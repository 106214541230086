@import url('https://fonts.googleapis.com/css2?family=league-spartan');

html,
body,
#root {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  left: 0;
  background-color: #c4c4c4;
  min-height: 800px;
}

h1, h2, h3, h4, h5, p {
  font-family: 'League Spartan' !important;
  padding: 0;
}

/* app : the react app div */
#app {
  position: relative;
  background-color: #c4c4c4;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* text_error : all the error text*/
#text_error {
  color: #972121;
}

/* label : all the input label*/

/* green_label : all the green label*/
#green_label {
  color: #83cb2b;
}

#number{
  font-family: 'Artifika' !important;
}

@media (max-width: 600px) {
  html,
  body,
  #root {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
  }
  #text {
    font-size: medium;
  }
  /* titre : all the page title*/
  #titre {
    font-size: large;
  }
}
