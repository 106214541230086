/* connexion : all connexion div */
#connexion {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  width: 100%;
  height: 100%;
  justify-content: center;
}
/* hr : all the text separation */
hr {
  width: 100px;
  margin-top: 20px;
  border-top: double 4px;
}

/* field : all field which contains text */
#field {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 25vw;
}
/* input : in field*/
input {
  text-align: center;
}

/* form : all form of the web app*/
#form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 0;
}

/* separator_or : the hr with the word 'ou'*/
#separator_or {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 30vw;
  align-items: flex-end;
}

/* google_connexion : the google connexion button*/
#google_connexion {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* main_button : all the main button of its app*/
#main_button {
  background-color: #0f5803;
  color: white;
}

/* main_button:hover : when user put his mouse on main button*/
#main_button:hover {
  color: #0f5803;
  background-color: white;
}

/* text_info : text info*/
#text_info {
  color: #0f5803;
}

/*for the responsive design*/
@media (max-width: 600px) {
  #titre {
    font-size: 30px;
  }
  #field {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 70vw;
  }
}
