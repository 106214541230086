#apiconnexion {
  position: absolute;
  width: 100%;
  top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#apiconnexion td {
  background-color: #3f3f3f;
}

#button_delete {
  background-color: red;
  color: #eeeeee;
}

#tableau_plateform {
  border-collapse: collapse;
  width: 90%;
  min-height: 600px;
}

#apiconnexion th,
#apiconnexion td {
  height: 45px;
  text-align: center;
  padding: 0;
}

@media (max-width: 600px) {
  #apiconnexion_tel {
    position: absolute;
    width: 100%;
    top: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #tableau_plateform_tel {
    border-collapse: collapse;
    width: 100%;
    max-width: 600px;
    min-height: 500px;
  }

  #tableau_plateform_tel td {
    background-color: #3f3f3f;
  }

  #button_delete {
    background-color: red;
    color: #eeeeee;
  }

  #td_api_key {
    overflow-wrap: break-word;
    max-width: 184px;
    font-size: small;
  }

  .fade-in {
    opacity: 0; /* set initial opacity to 0 */
    animation: fadeIn 1s ease-in-out forwards; /* use the fadeIn animation for 1 second */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0; /* start with opacity 0 */
    }
    to {
      opacity: 1; /* end with opacity 1 */
    }
  }
}
