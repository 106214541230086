#saisie {
  position: absolute;
  width: 100%;
  top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#link_to_api_connect {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#button_to_api_connect {
  color: #eeeeee;
  background-color: #8f6506;
  margin: 0;
  padding: 2%;
}

#text_message {
  color: #0f5803;
}

#div_button_saisie {
  display: flex;
  justify-content: space-evenly;
  width: 85%;
}

th {
  width: 10px;
}

td {
  text-align-last: center;
  padding: 1%;
}

#button_delete_plateform {
  background-color: #fb7575;
  color: #161616;
  margin: 0;
  width: 25px;
  padding: 2px;
}

#header_table_plateform {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: small;
}

.input_table {
  background-color: grey;
  color: #eeeeee;
  border-radius: 5px;
  width: 100%;
  border: none;
}

#button_saisie {
  width: 280px;
  background-color: #eeeeee;
  box-shadow: 7px 5px 7px;
}

#modal_plateform,
#modal_crypto,
#modal_stable {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
  background-color: rgb(15, 15, 15);
  color: #eeeeee;
  width: 100%;
}

#modal_modal_plateform {
  border-radius: 10px;
}

#modal_modal_plateform,
#modal_modal_crypto,
#modal_modal_stable {
  top: 40%;
}

#tbody_stable tr:last-child td {
  margin: 0;
  border-bottom: 1px solid #eeeeee;
}

thead th:first-child {
  border-top-left-radius: 15px;
}

#thead_exchange {
  font-size: larger;
}

thead th:last-child {
  border-top-right-radius: 15px;
}

#tbody_crypto td:first-child {
  border-top: 0;
}

#tbody_crypto,
#tbody_stable,
#table_saisie tbody {
  box-shadow: none;
}

#table_saisie {
  border-spacing: 0;
  height: 100%;
}

#table_saisie td,
#table_api_2 td {
  width: 75px;
  height: 45px !important;
}

#tbody_api_stable tr:last-child td {
  border-bottom: 1px solid #eeeeee;
}

#table_saisie td:last-child,
#table_api_2 td:last-child {
  border-right: solid 1px white;
}

#table_saisie thead th {
  border-bottom: 2px solid #eeeeee;
  border-collapse: collapse;
  color: #eeeeee;
  background-color: #3f3f3f;
  width: 85px;
  min-width: 85px;
  height: 45px;
}

td {
  border-right: 1px solid #eeeeee;
  border-collapse: collapse;
  color: #eeeeee;
  padding: 8px;
  font-size: 15px;
}

#table_saisie tbody {
  background-color: #3f3f3f;
  font-size: x-small;
}

#table_api {
  width: 45%;
  background-color: #3f3f3f;
  border-radius: 15px;
  text-align-last: center;
  color: #eeeeee;
  box-shadow: 4px 4px 4px rgb(27 26 26 / 29%);
}

#table_api_1 {
  width: 100%;
  overflow-x: scroll;
}

#table_api_2 {
  overflow-x: scroll;
  box-shadow: 4px 4px 4px rgba(27, 26, 26, 0.29);
}

#table_api #table_saisie_tel {
  width: 100%;
  background-color: #3f3f3f;
  border-radius: 15px;
  height: 95%;
}

#align_table {
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  min-height: 700px;
}

#table_api h2 {
  text-align: -webkit-center;
  border-bottom: solid 2px #eeeeee;
  margin-bottom: 2px;
  font-size: 2vh;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.fade-in {
  opacity: 0; /* set initial opacity to 0 */
  animation: fadeIn 1s ease-in-out forwards; /* use the fadeIn animation for 1 second */
}

@keyframes fadeIn {
  from {
    opacity: 0; /* start with opacity 0 */
  }
  to {
    opacity: 1; /* end with opacity 1 */
  }
}

@media (max-width: 722px) {
  #table_api_1 {
    height: 93%;
  }
}

@media (max-width: 600px) {
  #saisie_tel {
    position: absolute;
    width: 100%;
    top: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #div_button_saisie_tel {
    width: 95%;
    margin: 0;
  }

  .input_table_tel {
    background-color: grey;
    color: #eeeeee;
    border-radius: 10px;
    width: 100%;
    border: none;
  }

  #button_saisie_tel {
    width: 45%;
    background-color: #eeeeee;
    color: black;
    box-shadow: 2px 4px 4px;
  }

  #sous_div_button_saisie {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #table_saisie_tel {
    width: 98%;
    border-collapse: collapse;
    margin-right: 0;
  }

  #table_saisie_tel td {
    background-color: #3f3f3f;
    height: 45px !important;
  }

  #link_to_api_connect {
    width: 70%;
  }

  #table_api {
    width: 98%;
    background-color: #3f3f3f;
    border-radius: 15px;
    text-align-last: center;
    color: #eeeeee;
    overflow-x: scroll;
  }

  #table_api #table_saisie_tel {
    background-color: #3f3f3f;
    border-radius: 15px;
  }

  #table_api h3 {
    border-bottom: 2px solid #eeeeee;
  }

  .fade-in {
    opacity: 0; /* set initial opacity to 0 */
    animation: fadeIn 1s ease-in-out forwards; /* use the fadeIn animation for 1 second */
  }
}
