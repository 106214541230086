#change2fa {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

#text_change2fa {
  text-align: center;
  font-size: medium;
}
