@import url('https://fonts.googleapis.com/css2?family=league-spartan');


body {
  margin: 0;
  font-family: 'League Spartan' !important;
}

code {
  font-family: 'League Spartan' !important;
}
