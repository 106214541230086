/*
Attention !!!!
#field, hr, input, #form, #separator_or, #google_connexion, #main_button
se trouve dans connexion.css
*/

.google_button {
  border-radius: 20px !important;
  padding: 5px !important;
}

/* inscription : the inscription div*/
#inscription {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  width: 100%;
  height: 100%;
  justify-content: center;
}

/*for the responsive design*/
@media (max-width: 600px) {
  #inscription {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
