#dashboard {
  position: absolute;
  width: 100%;
  top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#graph_div {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: 100%;
  color: #eeeeee;
  margin-top: 1%;
}

#graph_crypto,
#graph_stable_crypto,
#graph_plateforme {
  background-color: #161616;
  padding: 1%;
  border-radius: 20px;
  text-align-last: center;
  max-width: 800px;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 18%;
}

#graph_crypto span,
#graph_stable_crypto span,
#graph_plateforme span {
  color: white !important;
}

#right_table_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #3f3f3f;
  width: 63%;
  border-radius: 20px;
  box-shadow: 4px 4px 4px rgba(27, 26, 26, 0.29);
}

#right_table_div td {
  height: 45px;
}

#graph_stable_crypto {
  margin-right: 10%;
}

#table_dashboard_crypto {
  width: 90%;
}

#table_dashboard_crypto tbody:last-child tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}

#table_dashboard_crypto tr {
  max-height: 45px !important;
}

#table_dashboard_plateforme {
  width: 30%;
}

thead {
  background-color: transparent;
  font-size: x-large;
}

th {
  border-bottom: 3px solid #eeeeee;
  border-collapse: collapse;
  color: #d1ceff;
  background-color: #3f3f3f;
}

#thead1 th:first-child {
  border-top-left-radius: 20px;
  border-right: hidden;
}

#thead1 th,
#thead2 th {
  border-bottom: 3px solid #eeeeee;
  padding: 8px;
  font-size: 75%;
  height: 45px;
}

#thead1 th:last-child {
  border-top-right-radius: 20px;
  border-right: hidden;
}

#table_dashboard_crypto,
#table_dashboard_plateforme,
td {
  border-collapse: collapse;
  color: #eeeeee;
}

#table_dashboard_plateforme {
  border-radius: 20px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

#thead2 {
  border-top: 3px solid #eeeeee;
  height: 45px;
}

#thead2 td:last-child {
  border-right: none;
}

#table_dashboard_crypto tbody,
#table_dashboard_plateforme tbody {
  background-color: #3f3f3f;
}

tbody {
  font-size: x-small;
}

#tbody tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
}

#tbody td {
  height: 45px;
}

#tbody tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
  border-right: hidden;
}

tbody td:last-child {
  border-right: hidden;
}

#dashboard_table {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
}

#reload_button {
  min-width: 260px;
  width: 25%;
  height: 100%;
  background-color: rgb(57, 57, 57);
  color: #eeeeee;
  align-self: center;
  margin: 0;
}

#tbody11 td #tbody td {
  font-size: 18px;
  font-weight: 400;
  border-bottom-right-radius: 15px;
}

#tbody td:first-child {
  font-size: 18px;
  font-weight: 700;
}

#table_dashboard_crypto #tbody11 td:first-child {
  font-size: 18px;
  font-weight: 700;
}

#tbody11 tr:first-child td:first-child {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

#modal_button {
  font-size: xx-small;
  border-radius: 15px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
  height: 20px;
  background-color: #eeeeee;
}

#modal_sort {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3%;
  background-color: rgb(15, 15, 15);
  color: #eeeeee;
}

#modal_modal_sort {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#modal_sort_div_button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 65%;
}

#modal_sort_button {
  background-color: darkslategrey;
  color: #eeeeee;
}

/* ------------------------------------------- */

#table_prix {
  border-collapse: collapse;
  margin-left: 10px;
  margin-right: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

#table_prix thead:nth-child(3) th {
  background-color: #a0a0a0;
  border-bottom: 0;
}

#table_prix thead:nth-child(1) th {
  border-bottom: 0;
}

#table_prix tbody {
  background-color: #a0a0a0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

#table_prix tbody:nth-child(2) tr:first-child td:first-child,
#table_prix tbody:nth-child(2) tr:first-child,
#table_prix tbody:nth-child(2) {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

#table_prix tbody:first-child {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

#table_prix th {
  border-bottom: 3px solid #eeeeee;
  padding: 8px;
  font-size: 75%;
  height: 45px;
}

#table_prix thead {
  height: 45px;
}

#table_prix tbody:last-child tr:last-child td:last-child,
#table_prix tbody:last-child tr:last-child {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.fade-in {
  opacity: 0; /* set initial opacity to 0 */
  animation: fadeIn 1s ease-in-out forwards; /* use the fadeIn animation for 1 second */
}

@keyframes fadeIn {
  from {
    opacity: 0; /* start with opacity 0 */
  }
  to {
    opacity: 1; /* end with opacity 1 */
  }
}

/* ------------------------------------------- */

@media screen and (max-width: 1300px) {
  #graph_stable_crypto {
    margin-right: 0%;
  }

  #graph_crypto,
  #graph_stable_crypto,
  #graph_plateforme {
    background-color: #161616;
    padding: 1%;
    border-radius: 20px;
    text-align-last: center;
    max-width: 800px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 20%;
  }
}

@media screen and (max-width: 1180px) {
  #graph_stable_crypto {
    margin-right: 0%;
  }

  #graph_crypto,
  #graph_stable_crypto,
  #graph_plateforme {
    background-color: #161616;
    padding: 1%;
    border-radius: 20px;
    text-align-last: center;
    max-width: 800px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 25%;
  }
}

@media screen and (max-width: 600px) {
  #dashboard_tel {
    position: absolute;
    width: 100%;
    top: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #div_graph {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }

  #reload_button {
    width: 150px;
    background-color: rgb(57, 57, 57);
    color: #eeeeee;
    align-self: center;
  }

  #graph_crypto_tel {
    width: 45%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #161616;
    padding: 2%;
    border-radius: 20px;
    box-shadow: 4px 5px 5px black;
    color: #eeeeee;
  }

  #table_dashboard_crypto {
    width: 95%;
    margin-right: 0;
  }

  #table_dashboard_crypto td:first-child {
    font-weight: 700;
  }

  #table_dashboard_crypto tbody td {
    border-collapse: collapse;
    color: #eeeeee;
    padding: 6px;
    font-size: 10px;
  }

  #modal_sort_div_button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
  }

  #padding_table_crypto {
    border-bottom: none !important;
  }

  #right_table_div {
    width: 100%;
  }

  #right_table_div td {
    font-size: 10px;
  }

  #right_table_div th {
    font-size: 1.5vh;
  }

  #table_prix thead {
    height: 35px;
  }

  #right_table_div td,
  #right_table_div th {
    height: 35px;
  }

  #table_dashboard_crypto #tbody11 td:first-child {
    font-size: 12px;
    font-weight: 700;
  }

  #thead1,
  #thead2 {
    height: 35px;
  }

  .fade-in {
    opacity: 0; /* set initial opacity to 0 */
    animation: fadeIn 1s ease-in-out forwards; /* use the fadeIn animation for 1 second */
  }
}
