/* header : the header div*/
#header,
#header_smartphone {
  position: fixed;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  background-color: #161618;
  top: 0;
  left: 0;
  padding: 10px;
  height: 4%;
  min-height: 55px;
  z-index: 100;
}

/* logo : the logo*/
#logo {
  width: 40px;
  border-radius: 10px;
  vertical-align: middle;
}

#link {
  width: 5%;
}

#button_header,
#button_header_signup,
#button_header_signin {
  color: white;
  font-size: large;
}

#button_header:hover {
  background-color: #EEEEEE !important;
  color: black !important;
}

#logo_youtube {
  width: 83px;
  height: 62px;
  vertical-align: middle;
  cursor: pointer;
}

#logo_youtube:hover {
  opacity: 0.8;
}

#left {
  width: 40%;
  position: relative;
  box-sizing: inherit;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}

#logo_user {
  width: 40px;
  border-radius: 100px;
  vertical-align: middle;
}

#button_header_popup {
  background-color: transparent;
  padding: 2px;
}

#button_header_popup:hover {
  background-color: white;
}

#segment {
  padding: 0;
  border: transparent;
  border-radius: 10px;
}

#right {
  width: 40%;
  position: relative;
  box-sizing: inherit;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}

#button_header {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 5px;
  text-align-last: center;
}

#button_header_signup {
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 2%;
}

#button_header_signin {
  color: white;
  background-color: #2196f3;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

#button_header_signup:hover {
  color: #2196f3;
}

#button_header_signin:hover {
  opacity: 0.8;
}

/*for the responsive design*/
@media (max-width: 600px) {
  #logo_youtube {
    width: 60px;
    height: 45px;
    vertical-align: middle;
  }

  #logo {
    width: 30px;
    border-radius: 3px;
    vertical-align: middle;
  }

  #button_header_signup {
    border-radius: 5px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: medium;
    margin-right: 1%;
  }

  #button_header_signin {
    color: white;
    background-color: #2196f3;
    border-radius: 5px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: medium;
  }

  #header_parent,
  header {
    position: fixed;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    top: 0;
    left: 0;
    background-color: #161618;
    z-index: 100;
  }

  #header_bottom {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #cbcbcb;
    height: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    z-index: 30;
  }

  #logo_user {
    width: 35px;
  }

  #button_header_top {
    color: white;
    font-size: large;
  }

  #button_header {
    color: black;
    font-size: medium;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  #button_header_signup:hover {
    color: #2196f3;
  }

  #button_header_signin:hover {
    opacity: 0.8;
  }

  #button_smartphone_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    margin: 5px;
    height: 98%;
    justify-content: space-evenly;
  }

  #button_smartphone_div:hover {
    background-color: white;
    color: black;
  }

  #idicone_header {
    width: 10%;
  }
}