#transaction {
  position: absolute;
  width: 100%;
  top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#achat_field {
  color: #22ff00a4;
}

#vente_field {
  color: #ff0000;
}

#transaction_form_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

#achat_crypto,
#vente_crypto {
  padding: 3%;
  margin: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#achat_crypto {
  color: #eeeeee;
  background-color: #0f5803;
  margin: 0;
  padding: 12px;
  width: 22%;
}

#vente_crypto {
  background-color: #972121;
  color: #eeeeee;
  margin: 0;
  padding: 12px;
  width: 22%;
}

#table_transaction {
  width: 90%;
  border-collapse: collapse;
  min-height: 700px;
}

#table_transaction td {
  background-color: #3f3f3f;
  font-size: 15px;
}

#table_transaction tr {
  height: 45px;
}

#table_transaction thead {
  background-color: #3f3f3f;
  font-size: 20px;
}

#table_transaction thead th:nth-child(1),
#table_transaction thead th:nth-child(2),
#table_transaction thead th:nth-child(3),
#table_transaction thead th:nth-child(4),
#table_transaction thead th:nth-child(5) {
  color: #eeeeee;
}

#table_transaction thead th:nth-child(5) {
  border-right: 1px solid #eeeeee;
}

#table_transaction tbody tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}

#table_transaction tbody tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
  border-right: hidden;
}

#transaction_time,
#plateform_montant,
#crypto_quantity {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

#input_date,
#input_plateform,
#selected_crypto,
#input_time,
#input_montant,
#selected_quantity {
  text-align: -webkit-center;
  font-family: "League Spartan" !important;
  height: 50px;
  font-size: 18px;
  border-radius: 5px;
  border: none;
}

#input_date,
#input_plateform,
#selected_crypto,
#input_plateform_api {
  background-color: #3f3f3f;
  color: #eeeeee;
  width: 40%;
}

#input_time,
#input_montant,
#selected_quantity {
  width: 60%;
}

#input_date::placeholder,
#input_plateform::placeholder,
#selected_crypto::placeholder,
#input_plateform_api::placeholder {
  color: #eeeeee;
}

#sup_plateforme {
  color: #d79a10;
}

#transaction #link_to_api_connect {
  width: 30%;
}

#div_button_transaction {
  width: 85%;
  display: flex;
  justify-content: space-evenly;
}

#div_button_transaction button {
  background: #eeeeee;
  border-radius: 5px;
  color: #3f3f3f;
  box-shadow: 7px 5px 7px;
  font-family: "League Spartan" !important;
  width: 280px;
}

#modal_transaction {
  padding: 5%;
  background-color: rgb(15, 15, 15);
  color: #eeeeee;
  top: 40%;
}

#div_modal_transaction_custom,
#div_modal_transaction_refresh {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#div_modal_transaction_refresh input {
  border: 0;
  border-radius: 5px;
  height: 4vh;
  font-family: "League Spartan" !important;
}

#modal_transaction_refresh_input {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#modal_transaction_refresh_input input {
  height: 40px;
}

#div_modal_transaction_refresh button {
  background: #8f6506;
  color: white;
  font-family: "League Spartan" !important;
  margin: 0;
}

#selected_crypto_api {
  color: #3f3f3f;
}

tr.padding td {
  height: calc(100% - 45px);
  padding: 0;
}

.fade-in {
  opacity: 0; /* set initial opacity to 0 */
  animation: fadeIn 1s ease-in-out forwards; /* use the fadeIn animation for 1 second */
}

@keyframes fadeIn {
  from {
    opacity: 0; /* start with opacity 0 */
  }
  to {
    opacity: 1; /* end with opacity 1 */
  }
}

@media (max-width: 600px) {
  #transaction_tel {
    position: absolute;
    width: 100%;
    top: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #table_transaction {
    width: 100%;
    min-height: 500px;
  }

  #achat_field {
    color: #24ff00;
  }

  #vente_field {
    color: #ff2727;
  }

  #achat_crypto,
  #vente_crypto {
    padding: 3%;
    margin: 0;
    height: 100%;
  }

  #achat_crypto {
    color: #eeeeee;
    background-color: #0f5803;
    margin: 0;
    padding: 6px;
    width: 50%;
  }

  #vente_crypto {
    background-color: #ff0000;
    color: #eeeeee;
    margin: 0;
    padding: 6px;
    width: 50%;
  }

  #transaction_time,
  #plateform_montant,
  #crypto_quantity,
  #transaction_form_bottom {
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    width: 70%;
  }

  #input_date,
  #input_plateform,
  #selected_crypto,
  #input_time,
  #input_montant,
  #selected_quantity {
    font-size: 3vw;
  }

  #input_plateform_api,
  #selected_crypto_api {
    width: 25%;
    font-size: 3vw;
  }

  #div_input {
    display: flex;
    justify-content: space-between;
  }

  #input_plateform_api,
  #selected_crypto_api {
    width: 45%;
  }

  #div_button_transaction button {
    font-size: 1.5vh;
  }

  .fade-in {
    opacity: 0; /* set initial opacity to 0 */
    animation: fadeIn 1s ease-in-out forwards; /* use the fadeIn animation for 1 second */
  }
}
