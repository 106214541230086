/* footer : the footer div*/
#footer{
    max-height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

#footer h5{
    margin: 0;
}