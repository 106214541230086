/* load : the load div*/
#wait {
    position: absolute;
    width: 100%;
    display: flex;
    height: 110%;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    z-index: 1500000;
    background-color: #c4c4c4;
  }
  
#wait_container{
    width: 100vw;
    display: flex;
    height: 100vh;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    z-index: 1500000;
    background-color: #c4c4c4;
}